import {
  BUSCA_REQUEST,
  BUSCA_SUCCESS,
  BUSCA_FAILURE,
  HANDLE_SENHA,
  HANDLE_BACKSPACE,
  SYNC_FUNCIONARIOS_REQUEST,
  CONFIRMA_SUCCESS,
  CANCELAR,
} from './types';

import * as service from './service';
import * as feedback from '../../_Common/Feedback/redux/actions';

export const buscaFuncionario = (senha) => {
  return async (dispatch) => {
    dispatch({ type: BUSCA_REQUEST });
    // ***SERVICE CALL***
    const funcionario = await service.getFuncBySenha(senha);
    console.log('busca funcionario', funcionario);
    if (funcionario) {
      dispatch({ type: BUSCA_SUCCESS, payload: funcionario });
    } else {
      dispatch(feedback.fail(`Senha ${senha} não existe`));
      dispatch({ type: BUSCA_FAILURE });
    }
  };
};

export const handleSenha = (value) => {
  return async (dispatch) => {
    dispatch({ type: HANDLE_SENHA, payload: value });
  };
};

export const handleBackSpace = () => {
  return async (dispatch) => {
    dispatch({ type: HANDLE_BACKSPACE });
  };
};

export const syncFuncionarios = (senha) => {
  return async (dispatch) => {
    dispatch({ type: SYNC_FUNCIONARIOS_REQUEST });
    // ***SERVICE CALL***
    service.syncFuncionarios();
  };
};

export const confirmaPonto = (funcionario, ponto) => {
  return async (dispatch) => {
    // ***SERVICE CALL***
    service.confirmaPonto(funcionario, ponto);
    dispatch(feedback.success(`Seu horário foi registrado com sucesso!`));
    dispatch({ type: CONFIRMA_SUCCESS });
  };
};

export const cancelar = () => {
  return async (dispatch) => {
    dispatch({ type: CANCELAR });
  };
};
