import React from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const EnhancedButton = (props) => {
  const { classes, actions } = props;
  return (
    <Button
      className={classes.button}
      disabled={props.disabled}
      variant="contained"
      size="large"
      onClick={() => actions.handleSenha(props.value)}>
      {props.value}
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedEnhancedButton = connect(
  null,
  mapDispatchToProps
)(EnhancedButton);

export default withStyles(styles)(connectedEnhancedButton);
