import React from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';

const BackSpace = (props) => {
  const { classes, actions } = props;
  return (
    <Button
      className={classes.backspace}
      // onClick={ this.handleClose }
      color="primary"
      variant="contained"
      size="large"
      onClick={() => actions.handleBackSpace()}>
      {/* <Icon size="large" className={classes.bspcIcon}>
        backspace
      </Icon> */}
      <FontAwesomeIcon
        icon={faBackspace}
        // className={classes.pullLeft}
        fixedWidth
        size="lg"
      />
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedBackSpace = connect(
  null,
  mapDispatchToProps
)(BackSpace);

export default withStyles(styles)(connectedBackSpace);
