export const SYNC_FUNCIONARIOS_REQUEST = 'SYNC_FUNCIONARIOS_REQUEST';
export const SYNC_FUNCIONARIOS_SUCCESS = 'SYNC_FUNCIONARIOS_SUCCESS';
export const SYNC_FUNCIONARIOS_FAILURE = 'SYNC_FUNCIONARIOS_FAILURE';

export const RESET_STATE = 'RESET_STATE';
export const HANDLE_SENHA = 'HANDLE_SENHA';
export const HANDLE_BACKSPACE = 'HANDLE_BACKSPACE';

export const BUSCA_REQUEST = 'BUSCA_REQUEST';
export const BUSCA_SUCCESS = 'BUSCA_SUCCESS';
export const BUSCA_FAILURE = 'BUSCA_FAILURE';

export const CONFIRMA_REQUEST = 'CONFIRMA_REQUEST';
export const CONFIRMA_SUCCESS = 'CONFIRMA_SUCCESS';
export const CONFIRMA_FAILURE = 'CONFIRMA_FAILURE';

export const SHOW_MODAL_CONFIRMA = 'SHOW_MODAL_CONFIRMA';

export const CANCELAR = 'CANCELAR';
