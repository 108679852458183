import {
  BUSCA_REQUEST,
  BUSCA_SUCCESS,
  BUSCA_FAILURE,
  HANDLE_SENHA,
  HANDLE_BACKSPACE,
  CONFIRMA_SUCCESS,
  CANCELAR,
} from './types';

const INITIAL_STATE = {
  senha: '',
  confirma: false,
  funcionario: {},
  loading: false,
  confirmaLock: false,
};

export default (state = INITIAL_STATE, action) => {
  console.log(action);
  switch (action.type) {
    case HANDLE_SENHA:
      return {
        ...state,
        senha: `${state.senha}${action.payload}`.slice(0, 4),
      };

    case HANDLE_BACKSPACE:
      return {
        ...state,
        senha: state.senha.slice(0, -1),
      };

    case BUSCA_REQUEST:
      return {
        ...state,
        loading: true,
        confirmaLock: true,
      };
    case BUSCA_SUCCESS:
      return {
        ...state,
        confirma: !state.confirma,
        funcionario: action.payload,
        loading: false,
        senha: '',
      };
    case BUSCA_FAILURE:
      return {
        ...state,
        loading: false,
        confirmaLock: false,
      };

    case CONFIRMA_SUCCESS:
      return INITIAL_STATE;

    case CANCELAR:
      return INITIAL_STATE;

    default:
      return state;
  }
};
