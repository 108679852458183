import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';

const SenhaDisplay = (props) => {
  const { senha } = props;
  return (
    <Typography variant="h3" gutterBottom>
      {senha !== '' ? senha : '- - - -'}
    </Typography>
  );
};

const mapStateToProps = (state) => {
  const store = state.app;
  return {
    senha: store.senha,
  };
};

export default connect(mapStateToProps)(SenhaDisplay);
