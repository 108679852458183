import axios from 'axios';

const token = localStorage.getItem('user');

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || 'https://3rlmkud6tf6bgr5t2ckedq4ka40hynzb.lambda-url.sa-east-1.on.aws/',
  headers: {
    Authorization: token,
  },
});

export async function send(verb, endpoint, data) {
  let res = '';
  try {
    switch (verb) {
      case 'GET':
        res = await api.get(endpoint);
        break;
      case 'POST':
        res = await api.post(endpoint, data);
        break;
      case 'PUT':
        res = await api.put(endpoint, data);
        break;
      case 'DELETE':
        res = await api.delete(endpoint, data);
        break;
      default:
        break;
    }
    console.log(res);

    return { success: true, msg: res.data.msg, data: res.data };
  } catch (error) {
    return errorHandler(error);
  }
}

function errorHandler(error) {
  const { response } = error;
  console.log(error.response);

  if (!response) {
    return { error: 'Sem conexão' };
  }

  if (response.status === 400) {
    return { error: response.data.error };
  }

  // auto logout if 401 response returned from api
  if (response.status === 401) {
    // window.location.reload(true);
    return { error: response.data.error };
  }

  return { error: response.data.error };
}
