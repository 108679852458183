import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmaPonto from './ConfirmaPonto';
import styles from './styles';
import Clock from './clock';
import EnhancedButton from './EnhancedButton';
import BackSpace from './BackSpace';
import BtnRegistrar from './BtnRegistrar';
import SenhaDisplay from './SenhaDisplay';

const Home = (props) => {
  const { loading, confirma, actions, classes } = props;

  useEffect(() => {
    actions.syncFuncionarios();
  }, []);

  if (confirma) {
    return <ConfirmaPonto />;
  }
  return (
    <div className={classes.main}>
      <Grid container spacing={32} direction='column' alignItems='center'>
        <Grid item xs={12}>
          <Clock />
        </Grid>

        <Grid item xs={12}>
          <SenhaDisplay />
        </Grid>

        <Grid item xs={12}>
          <EnhancedButton value={1} />
          <EnhancedButton value={2} />
          <EnhancedButton value={3} />
        </Grid>
        <Grid item xs={12}>
          <EnhancedButton value={4} />
          <EnhancedButton value={5} />
          <EnhancedButton value={6} />
        </Grid>
        <Grid item xs={12}>
          <EnhancedButton value={7} />
          <EnhancedButton value={8} />
          <EnhancedButton value={9} />
        </Grid>

        <Grid item xs={12}>
          <EnhancedButton value='.' disabled />
          <EnhancedButton value={0} />
          <BackSpace />
        </Grid>
      </Grid>
      <BtnRegistrar />
      <Typography variant='subtitle2' align='center' gutterBottom>
        *Uma foto será armazenada automaticamente ao registrar o horário.
      </Typography>
      <Typography variant='caption' align='center'>
        versão 0.5.2
      </Typography>

      <Dialog open={loading} fullWidth aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          <Typography variant='h4'>Buscando dados...</Typography>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const store = state.app;
  return {
    senha: store.senha,
    confirma: store.confirma,
    funcionario: store.funcionario,
    loading: store.loading,
    confirmaLock: store.confirmaLock,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);

export default withStyles(styles)(connectedHome);
