import { send } from '../../../_helpers/api.config';

const apiURL = '/api/login';

export const login = async (email, password) => {
  const endpoint = apiURL;
  const verb = 'POST';
  const data = { email, password };
  const res = await send(verb, endpoint, data);
  if (res.success) {
    localStorage.setItem('user', res.data.token);
  }
  return res;
};

export const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
};
