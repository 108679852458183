import React from 'react';
import { Grid} from '@material-ui/core';

export default props => {
  return (
    <Grid container spacing={16}>
      {props.children}
    </Grid>
  );
};
