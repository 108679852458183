import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDharmachakra } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core';

const Spinner = (props) => {
  const { classes, size } = props;
  return (
    <div className={classes.root}>
      <FontAwesomeIcon icon={faDharmachakra} spin fixedWidth size={size} />
    </div>
  );
};

const styles = (theme) => ({
  root: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(Spinner);
