export default (theme) => ({
  main: {
    backgroundColor: 'seashell',
  },
  button: {
    fontSize: '2em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    maxWidth: '4em',
    minWidth: '4em',
    maxHeigth: '5em',
    minHeigth: '5em',
    padding: '0.5em 2em',
  },

  backspace: {
    fontSize: '2em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    maxWidth: '4em',
    minWidth: '4em',
    maxHeigth: '5em',
    minHeigth: '5em',
    // padding: '0.5em 2em',
  },

  bspcIcon: {
    // fontSize: '1.12em',
    // maxWidth: '4em',
    // minWidth: '4em',
    maxHeigth: '5em',
    minHeigth: '5em',
    padding: '1em 2em',
  },

  confirma: {
    fontSize: '2em',
    marginTop: '1em',
    marginBottom: '0.5em',
  },
});
