import React, { Component } from 'react';
import locale from 'date-fns/locale/pt';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: format(new Date(), 'HH:mm:ss', { locale }),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: format(new Date(), 'HH:mm:ss', { locale }),
    });
  }
  render() {
    return (
      <Typography variant="h1">
        {this.state.time}
      </Typography>
    );
  }
}

export default Clock;
