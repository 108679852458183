import Dexie from 'dexie';

const db = new Dexie('cavalli');
db.version(1).stores({
  funcionarios: `++id,nome,cpf,senha,_id`,
  pontos: `++id,nome,senha,ponto,_id`,
});
db.open();

export default db;
