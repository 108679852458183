import React from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const BtnRegistrar = (props) => {
  const { classes, confirmaLock, senha, actions } = props;
  return (
    <Button
      className={classes.registrar}
      color="primary"
      variant="contained"
      size="large"
      fullWidth
      disabled={confirmaLock}
      onClick={() => actions.buscaFuncionario(senha)}>
      registrar
    </Button>
  );
};

const mapStateToProps = (state) => {
  const store = state.app;
  return {
    confirmaLock: store.confirmaLock,
    senha: store.senha,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedBtnRegistrar = connect(
  mapStateToProps,
  mapDispatchToProps
)(BtnRegistrar);

export default withStyles(styles)(connectedBtnRegistrar);
