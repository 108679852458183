import { send } from './api.config';
import DB from './DB';

// function resolve(path, obj) {
//   return path.split('.').reduce(function(prev, curr) {
//     return prev ? prev[curr] : null;
//   }, obj || this);
// }

export async function getFromCache(table, data) {
  let res = false;
  if (data) {
    res = await DB[table].get(data);
  } else {
    // get all records from this table
    res = await DB[table].toArray();
  }
  // se achou algo, retorne
  if (res && res.length !== 0) return res;
  // retorno padrao caso nao ache nada
  return false;
}

export async function syncCache(table, verb, endpoint, data) {
  const res = await send(verb, endpoint, data);
  if (res.success) {
    const results = res.data;
    DB[table].bulkDelete('id');
    DB[table].bulkPut(results);
  }
  return res.data;
}

export async function storeAndSend(table, verb, endpoint, data) {
  // salva local
  const index = await DB[table].add(data);
  console.log('CACHE INDEX: ', index);

  // envia request
  const res = await send(verb, endpoint, data);
  if (res.success) {
    // se deu tudo certo, apaga o registro local
    DB[table].where({ id: index }).delete();
    return res.data;
  }
  return false;
}

export async function deleteCache(table, index) {
  console.log('tentando deletar cache');
  console.log('index: ', index);

  DB[table].where({ id: index }).delete();
}
