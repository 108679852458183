import { send } from '../../../_helpers/api.config';
import { getFromCache, syncCache, storeAndSend, deleteCache } from '../../../_helpers/data.cache';

export const syncFuncionarios = async () => {
  const endpoint = '/api/funcionario/';
  const verb = 'GET';
  const data = null;

  // *** nao existe no cache, pega da API
  console.log('sending request');
  const res = await syncCache('funcionarios', verb, endpoint, data);
  console.log('res', res);

  if (!res) {
    const cache = await getFromCache('funcionarios', data);
    if (cache) {
      console.log(cache);
      return cache;
    }
  }
  return res;
};

export const getFuncBySenha = async (senha) => {
  const endpoint = `/api/open/senha/${senha}`;
  const verb = 'GET';
  const data = senha;
  let funcionario = await getFromCache('funcionarios', { senha });
  if (funcionario && funcionario._id) return funcionario;
  console.log('could not get from cache');
  // nao existe no cache, pega da API
  const res = await send(verb, endpoint, data);
  if (res.success) {
    // já que nao estava no cache, atualize o cache
    syncFuncionarios();
    const { nome, cpf, _id } = res.data;
    return { nome, cpf, _id, senha: res.data.senha };
  }
  // o funcionario nao existe mesmo
  return false;
};

export const confirmaPonto = async (funcionario, ponto) => {
  const endpoint = `/api/open/registraPonto`;
  const verb = 'POST';
  const data = { funcionario, ponto };
  const res = await storeAndSend('pontos', verb, endpoint, data);
  console.log(res);
  if (res.success) {
    sincronizaPontosLocais();
    return true;
  }
  // se deu tudo certo, verifique se existem pontos para serem enviados
};

export const sincronizaPontosLocais = async () => {
  const pontos = await getFromCache('pontos');
  if (pontos && pontos.length !== 0) {
    console.log('tem pontos a enviar');
    pontos.forEach(async (item) => {
      console.log(item);
      await __system_ConfirmaPonto(item);
    });
  }
};

async function __system_ConfirmaPonto(ponto) {
  const endpoint = `/api/open/registraPonto`;
  const verb = 'POST';
  // envia pontos
  const res = await send(verb, endpoint, ponto);
  // se deu certo, apague do banco local
  if (res.success) {
    console.log('ID PRA DELETAR: ', res.data.id);

    await deleteCache('pontos', res.data.id);
    console.log('ponto sincronizado e deletado do local storage');
    // console.log(ponto);
  }
}
