import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import styles from './styles';

const CancelarOuSalvar = (props) => {
  const { classes } = props;
  const { isValid, handleSubmit, handleCancel } = props.save;
  // console.log(props);

  return (
    <div className={classes.cancelarOuSalvar}>
      <Button
        className={classes.button}
        variant="outlined"
        color="default"
        onClick={handleCancel || props.history.goBack}
      >
        cancelar
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={!isValid}
        onClick={handleSubmit}
      >
        salvar
      </Button>
    </div>
  );
};

export default withRouter(withStyles(styles)(CancelarOuSalvar));
