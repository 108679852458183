export default (theme) => ({
  button: {
    fontSize: '2em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    maxWidth: '4em',
    minWidth: '4em',
    maxHeigth: '5em',
    minHeigth: '5em',
    padding: '0.5em 2em',
  },
});
