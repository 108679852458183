import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import * as Actions from '../redux/actions';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

class ConfirmaPonto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.confirma,
      success: false,
      error: false,
    };
    this.registraPonto = this.registraPonto.bind(this);
  }

  handleClose = () => {
    // action reset state
    this.props.actions.cancelar();
    this.setState({ open: false });
  };

  registraPonto() {
    const ponto = new Date();
    const { funcionario } = this.props;
    this.props.actions.confirmaPonto(funcionario, ponto);
    this.handleClose();
  }

  render() {
    const { nome, cpf } = this.props.funcionario;
    return (
      <Dialog
        open={this.state.open}
        fullWidth
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h4">Olá, {nome}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h5" gutterBottom>
              Por favor, confira se seus dados estão corretos e confirme o
              registro.
            </Typography>
            <br />
            <Typography variant="button">SEU NOME</Typography>
            <Typography variant="h3" gutterBottom>
              {nome}
            </Typography>
            <Typography variant="button">SEU CPF</Typography>
            <Typography variant="h3" gutterBottom>
              {cpf}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ fontSize: '2em' }}
            onClick={this.handleClose}
            color="default"
            variant="contained">
            Cancelar
          </Button>
          <Button
            style={{ fontSize: '2em' }}
            onClick={this.registraPonto}
            color="primary"
            variant="contained">
            Sim, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const store = state.app;
  return {
    confirma: store.confirma,
    funcionario: store.funcionario,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedConfirmaPonto = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmaPonto);

export default withStyles(styles)(connectedConfirmaPonto);
