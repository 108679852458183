import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const FormTextField = (props) => {
  const { label, field, formik, xs, md, type, styleProps } = props;
  const value = formik.values[field];
  const touched = formik.touched[field];
  const error = formik.errors[field];
  const change = formik.change;
  const tipo = type ? type : 'text';
  const InputProps = styleProps ? styleProps.InputProps : null;
  const InputLabelProps = styleProps ? styleProps.InputLabelProps : null;

  return (
    <Grid item xs={xs} md={md}>
      <TextField
        id={field}
        name={field}
        helperText={touched ? error : ''}
        error={touched && Boolean(error)}
        label={label}
        fullWidth
        value={value}
        onChange={change.bind(null, field)}
        variant="outlined"
        type={tipo}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
      />
    </Grid>
  );
};

export default FormTextField;
