import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import * as Actions from './redux/actions';
import { connect } from 'react-redux';
import styles from './styles';
import { FormGroup, FormTextField } from '../_Common/Form';
import Spinner from '../_Common/Spinner';

const Form = (props) => {
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    classes,
    actions,
  } = props;

  const change = (name, e) => {
    e.persist();
    setFieldTouched(name, true, false);
    handleChange(e);
  };

  const formProps = {
    errors,
    touched,
    values: props.values,
    change,
  };

  if (props.loggedIn) {
    window.location.href = '/app';
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <FormTextField
          label="Email"
          field="email"
          xs={12}
          md={12}
          formik={formProps}
          type="email"
        />

        <FormTextField
          label="Senha"
          field="password"
          xs={12}
          md={12}
          formik={formProps}
          type="password"
        />
      </FormGroup>

      {props.loggingIn ? (
        <Spinner size="2x" />
      ) : (
        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={() => actions.login(values)}
          color="primary"
          className={classes.button}>
          <FontAwesomeIcon
            icon={faSignInAlt}
            className={classes.pullLeft}
            fixedWidth
            size="lg"
          />
          Entrar
        </Button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  const store = state.login;
  return {
    loggingIn: store.loggingIn,
    loggedIn: store.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

export default withStyles(styles)(connectedForm);
