export default (theme) => ({
  button: {
    marginLeft: '0.5em',
  },

  cancelarOuSalvar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1em',
  },
});
