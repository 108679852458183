import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/_Common/PrivateRoute';
import Feedback from './components/_Common/Feedback';
import Login from './components/Login';
import Home from './components/home';
import { bindActionCreators } from 'redux';
import * as Actions from './components/home/redux/actions';
import { connect } from 'react-redux';

class App extends Component {
  componentDidMount() {
    console.log('mounted');
    console.log(this.props);
    this.props.actions.syncFuncionarios();
  }
  render() {
    if (window.IndexedDB) {
      console.log('IndexedDB is supported');
    }
    return (
      <BrowserRouter>
        <Feedback />
        <Switch>
          <Route exact path='/' component={Login} />
          <PrivateRoute exact path='/app' component={Home} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedApp = connect(null, mapDispatchToProps)(App);

export default connectedApp;
